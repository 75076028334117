import React, { useState } from 'react';
import { SORT } from './constant';
import { graphql, useStaticQuery } from 'gatsby';
import withConvertToFilterList from 'components/common/withConvertToFilterList';
import { SortType } from 'components/filter/type';
import { buildPaginationParams, mergeParams } from 'utils/apiUtils';
import useApi from 'utils/useApi';
import tw from 'twin.macro';
import FilterOnWeb from 'components/filter/FilterOnWeb';
import FilterOnMobile from 'components/filter/FilterOnMobile';
import { LayoutTypes } from 'components/articleList/types';
import ArticleCard from 'components/articleList/ArticleCardV2';
import ArticleListV2 from 'components/articleList/indexV2';
import { pick } from 'lodash';
import { useIntl } from 'react-intl';

const FilterAndListingWrapper = tw.div`
  grid grid-cols-1 lg:grid-cols-[2fr_5fr] lg:min-h-[950px]
`;
const WebContainer = tw.div`
  hidden lg:block z-[2] bg-white pb-6 pt-[48px] pr-[40px]
  
`;
const MobileContainer = tw.div`
  lg:hidden z-[2] bg-white
`;

const CaseStudiesFilterAndListing = () => {
  const { formatMessage } = useIntl();
  const [fetchParams, setFetchParams] = useState<Record<string, unknown>>(
    buildPaginationParams({
      sort: ['publishedAt:desc'],
      filters: {},
    }),
  );
  const updateFilters = (params: Record<string, unknown>) => {
    const filterParams = mergeParams(fetchParams, params);
    setFetchParams(filterParams);
    fetchData(filterParams);
  };
  const graphQlData = useStaticQuery(graphql`
    query {
      allStrapiHfsCaseStudyTopic {
        nodes {
          name
          slug
        }
      }
      allStrapiHfsCaseStudyLocation {
        nodes {
          name
          slug
        }
      }
    }
  `);

  const caseTopicSort = withConvertToFilterList(
    graphQlData?.allStrapiHfsCaseStudyTopic?.nodes,
    'hfs_case_study_topic',
    formatMessage({ id: 'caseStudies.sort.topic' }),
  );
  const caseLocationSort = withConvertToFilterList(
    graphQlData?.allStrapiHfsCaseStudyLocation?.nodes,
    'hfs_case_study_location',
    formatMessage({ id: 'caseStudies.sort.country' }),
  );

  const { data, loading, fetchData } = useApi({
    path: `/hfs-case-study-articles`,
    autoFetchWithParams: fetchParams,
  });
  const handleSort = (chooseValue: string) => {
    const sortItem = SORT.items.find(({ value }) => value === chooseValue);
    if (sortItem) {
      const { strapiValue, strapiKey } = sortItem;
      updateFilters?.(
        buildPaginationParams({
          sort: [`${strapiKey}:${strapiValue}`],
        }),
      );
    }
  };

  const renderItemList = ({ id, attributes }) => {
    const {
      title,
      description,
      cover,
      hfs_case_study_topic,
      slug,
      readingTime,
    } = attributes;
    const card = {
      title,
      readingTime,
      description,
      cover: cover.data.attributes,
      id,
      category: pick(
        hfs_case_study_topic.data.attributes,
        'name',
        'slug',
        'backgroundHexCodeColor',
        'textHexColorCode',
      ),
      slug,
    };
    return <ArticleCard key={id} {...card} id="financial/case-studies" />;
  };
  const mobileFilterList: SortType[] = [caseLocationSort, caseTopicSort];

  return (
    <FilterAndListingWrapper>
      <WebContainer>
        <FilterOnWeb
          sortList={SORT}
          sortByCountry={caseLocationSort}
          sortByCategory={[caseTopicSort]}
          updateFilters={updateFilters}
          handleSort={handleSort}
        />
      </WebContainer>
      <MobileContainer>
        <FilterOnMobile
          sortList={SORT}
          filterList={mobileFilterList}
          updateFilters={updateFilters}
          handleSort={handleSort}
        />
      </MobileContainer>

      <ArticleListV2
        renderListItem={renderItemList}
        data={data}
        loading={loading}
        updateFilters={updateFilters}
        layoutType={LayoutTypes.TWO_COLS}
      />
    </FilterAndListingWrapper>
  );
};

export default CaseStudiesFilterAndListing;
