import React from 'react';
import translation from './translation';
import Hero from './CaseStudyHero';
import CaseStudiesFilterAndListing from './CaseStudiesFilterAndListing';
import { withIntl } from 'LanguageProvider/withLanguageProvider';
import LayoutContainer from 'components/LayoutContainer';

const CaseStudies = () => {
  return (
    <LayoutContainer className="pt-[84px]">
      <Hero />
      <CaseStudiesFilterAndListing />
    </LayoutContainer>
  );
};

export default withIntl(translation)(CaseStudies);
