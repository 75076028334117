import * as React from 'react';
import translation from 'intl';
import { graphql } from 'gatsby';
import SEOGraphQL from 'components/SEOGraphQL';
import { PREFIX_CANONICAL_URL } from 'components/constant';
import BreadcrumbList from 'components/structuredData/BreadcrumbList';
import { PageSEOType } from 'components/types';
import CaseStudies from 'components/pages/HFS/caseStudies';
import HFSLayout from 'components/HFS/HFSLayout';
import { withIntl } from 'LanguageProvider/withLanguageProvider';

const pageUrl = `${PREFIX_CANONICAL_URL}/financial/case-studies`;

const CaseStudiesPage = () => {
  return (
    <HFSLayout headerStyle="light">
      <BreadcrumbList
        pageLabelId="structuredData.caseStudies"
        pageUrl={pageUrl}
        isHFSPage={true}
      />
      <CaseStudies />
    </HFSLayout>
  );
};
export default withIntl(translation)(CaseStudiesPage);

export const Head = ({
  data,
}: {
  data: {
    allStrapiHfsCaseStudyPage: {
      nodes: PageSEOType[];
    };
  };
}) => {
  return (
    <SEOGraphQL
      data={data.allStrapiHfsCaseStudyPage.nodes[0]}
      canonicalURL={pageUrl}
    />
  );
};

export const query = graphql`
  query {
    allStrapiHfsCaseStudyPage {
      nodes {
        seo {
          preventIndexing
        }
        SEO {
          metaDescription
          metaTitle
          metaSocial {
            title
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
